@import '~darrius/src/styles/mixins/media';

.float-button {
  @include media(small) {
    display: var(--none, none);
  }

  all: unset;
  position: fixed;
  right: 16px;
  bottom: 16px;
  z-index: 1002;
  border-radius: 50%;
  background-color: var(--action-300);
  width: 52px;
  height: 52px;
  text-align: center;

  &__hide {
    display: var(--none, none);
  }
}
