@import '~darrius/src/styles/mixins/media';

.carousel {
  @include media(normal) {
    &[data-children-count='3'] {
      :global {
        .carousel__prev,
        .carousel__next {
          display: none;
        }
      }

      .carousel__item[data-is-next='true'],
      .carousel__item[data-is-previous='true'] {
        flex: 1;
      }

      .carousel__item[data-is-current='true'] {
        order: 0;
      }

      .carousel__item[data-is-next='true'] {
        order: 1;
      }

      .carousel__item[data-is-previous='true'] {
        order: 2;
      }
    }
  }

  position: relative;
  overflow: hidden;

  :global {
    .carousel__prev,
    .carousel__next {
      display: flex;
      position: absolute;
      top: calc(50% - 1.5rem);
      align-items: center;
      justify-content: center;
      z-index: 1;
      outline: var(--none, none);
      border: 0;
      border-radius: 100%;
      box-shadow: 0 0 6px rgb(0 0 0 / 10%);
      background-color: var(--shade-900);
      cursor: pointer;
      width: 3rem;
      height: 3rem;
      user-select: none;

      &:hover,
      &:active,
      &:focus {
        background-color: var(--shade-700);
      }
    }

    .carousel__prev {
      left: 5px;
      transform: rotate(90deg);
    }

    .carousel__next {
      right: 5px;
      transform: rotate(-90deg);
    }
  }

  &__items {
    @include media(normal) {
      padding: 16px 64px;
    }

    display: flex;
    position: relative;
    align-items: stretch;
    justify-content: center;
    padding: 16px 0;
    width: 100%;
    height: 100%;
    gap: 20px;
    overflow: hidden;
  }

  &__item {
    display: none;
    flex: 1;
    align-items: center;
    justify-content: center;

    &[data-is-current='true'],
    &[data-is-previous='true'],
    &[data-is-next='true'] {
      @include media(normal) {
        min-width: unset;
      }

      display: block;
      min-width: 65%;
    }

    &[data-is-previous='true'] {
      order: 0;
    }

    &[data-is-current='true'] {
      order: 1;
    }

    &[data-is-next='true'] {
      order: 2;
    }
  }
}
