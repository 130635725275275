.otp_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  margin-top: var(--size-spacing-stack-500);

  height: 80px;
}

.otp_content {
  display: flex;
}

.otp_loading {
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    margin-top: var(--size-spacing-inline-200);

    border-top-color: var(--color-action-500);
    border-right-color: var(--color-action-500);
    border-bottom-color: var(--color-action-500);

    width: 2rem;
    height: 2rem;
  }
}

.otp_slot__active {
  outline: var(--color-neutral-dark-600);
  outline-width: 2px;
  outline-style: solid;
  border: none;
}

.otp_slot__disabled {
  background-color: var(--color-neutral-light-200);
  color: var(--color-neutral-dark-100);
}

.otp_slot__success {
  outline: var(--color-positive-500);
  outline-width: 2px;
  outline-style: solid;
  background-color: var(--color-positive-200);
  color: var(--color-neutral-dark-600);
}

.otp_slot__error {
  outline: var(--color-negative-500);
  outline-width: 2px;
  outline-style: solid;
  background-color: var(--color-negative-200);
  color: var(--color-neutral-dark-600);
}

.otp_slot {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;

  margin: 0 var(--size-spacing-inline-100);

  border: 1px solid var(--color-neutral-light-600);
  border-radius: var(--size-border-radius-m);

  padding: var(--size-spacing-stack-fluid-700);

  width: 2.5rem;
  height: 64px;
}

.otp_slot__char {
  font-size: var(--size-font-xl);
  font-weight: var(--font-weight-regular);
}

.opt_fake_caret__content {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

@keyframes blink {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.opt_fake_caret__dash {
  background-color: var(--color-action-500);
  width: 2px;
  height: 2rem;

  animation-name: blink;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
}
