@import '~darrius/src/styles/mixins/media';
@import '~darrius/src/styles/mixins/scale';

.items {
  margin-left: var(--size-spacing-inline-300);
  line-height: var(--font-line-height-wide);

  li {
    margin-top: var(--size-spacing-stack-100);
    margin-bottom: var(--size-spacing-stack-100);
  }

  &--root {
    margin-left: 0;

    > * {
      @include media(normal) {
        padding-top: var(--size-spacing-stack-400);
        padding-bottom: var(--size-spacing-stack-400);
      }

      padding-bottom: var(--size-spacing-stack-400);
    }
  }
}

.link {
  border-radius: var(--size-border-radius-s);
  color: var(--color-neutral-dark-300);
  font-size: var(--size-font-base);
  font-weight: var(--font-weight-regular);

  &:visited {
    color: var(--color-neutral-dark-300);
  }

  &:active,
  &:focus {
    outline: var(--size-border-width-heavy) solid var(--color-action-300);
  }

  &--root {
    line-height: var(--font-line-height-normal);
    font-size: var(--size-font-xl);
    font-weight: var(--font-weight-bold);
  }
}
