@import '~darrius/src/styles/mixins/media';

.pinInput__container {
  input {
    @include media(normal) {
      letter-spacing: var(--size-spacing-inline-300);
    }

    text-align: center;
    letter-spacing: var(--size-spacing-inline-100);

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      letter-spacing: 0;
    }

    &::placeholder::-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      letter-spacing: 0;
    }

    &:-ms-input-placeholder {
      /* Microsoft Edge */
      letter-spacing: 0;
    }
  }
}
