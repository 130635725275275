@import '~darrius/src/styles/mixins/media';

.faq {
  &__title {
    @include media(normal) {
      margin-bottom: var(--size-spacing-stack-1200);
    }

    margin-bottom: var(--size-spacing-stack-500);
  }

  &__item {
    @include media(normal) {
      padding: var(--size-spacing-inset-stretch-400);
    }

    margin: 0;
    border: var(--size-border-width-thin) solid var(--color-neutral-light-400);
    border-radius: var(--size-border-radius-m);
    background-color: var(--color-neutral-light-100);
    padding: var(--size-spacing-inset-stretch-100);

    & + & {
      @include media(normal) {
        margin-top: var(--size-spacing-stack-700);
      }

      margin-top: var(--size-spacing-stack-300);
    }

    :global {
      h3 {
        margin-bottom: var(--size-spacing-stack-fluid-200);
        line-height: var(--font-line-height-compact);
        color: var(--color-neutral-dark-600);
        font-size: var(--size-font-m);
        font-weight: var(--font-weight-semibold);
      }

      p {
        line-height: var(--font-line-height-normal);
        color: var(--color-neutral-dark-400);
        font-size: var(--size-font-base);
        font-weight: var(--font-weight-regular);
      }
    }
  }
}
