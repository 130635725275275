@import '~darrius/src/styles/mixins/media';

.average_price {
  display: block;
  border: var(--size-border-width-thin) solid var(--color-neutral-light-400);
  border-radius: var(--size-border-radius-m);
  background-color: var(--color-neutral-light-100);
  padding: var(--size-spacing-inset-stretch-100);

  &:hover,
  &:active,
  &:focus {
    outline: var(--size-border-width-heavy) solid var(--color-action-300);
  }

  &__title {
    margin-bottom: var(--size-spacing-inline-300);
    line-height: var(--font-line-height-compact);
    color: var(--color-positive-600);
    font-size: var(--size-font-base);
    font-weight: var(--font-weight-semibold);
  }

  &__average {
    &_value {
      @include media(normal) {
        font-size: var(--size-font-3xl);
      }

      margin-bottom: var(--size-spacing-stack-200);
      line-height: var(--font-line-height-compact);
      color: var(--color-neutral-dark-600);
      font-size: var(--size-font-xl);
      font-weight: var(--font-weight-semibold);
    }

    &_label {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      height: var(--size-spacing-stack-fluid-900);
      overflow: hidden;
      line-height: var(--font-line-height-normal);
      color: var(--color-neutral-dark-400);
      font-size: var(--size-font-base);
      font-weight: var(--font-weight-regular);
    }
  }

  &__separator {
    margin: var(--size-spacing-stack-fluid-200) -17px;
    border-color: transparent;
    border-top-color: var(--color-neutral-light-400);
  }

  &__maximum,
  &__minimum {
    &_label {
      margin-bottom: var(--size-spacing-inline-100);
      line-height: var(--font-line-height-normal);
      color: var(--color-neutral-dark-400);
      font-size: var(--size-font-base);
      font-weight: var(--font-weight-regular);
    }

    &_value {
      line-height: var(--font-line-height-compact);
      color: var(--color-neutral-dark-600);
      font-size: var(--size-font-m);
      font-weight: var(--font-weight-semibold);
    }
  }
}
