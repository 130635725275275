.dialog-loading__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    margin-top: var(--size-spacing-stack-300);
    color: var(--color-neutraldark-600);
    font-size: var(--size-font-xl);
    font-weight: var(--font-weight-semibold);
  }
}

.dialog-loading__custom-loading {
  display: inline-block;

  border: var(--size-border-width-heavy) solid transparent;
  border-radius: 50%;
  border-color: transparent var(--color-action-500) var(--color-action-500);
  min-width: 80px;
  min-height: 80px;

  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
