@import '~darrius/src/styles/mixins/media';

.loading {
  @include media(normal) {
    width: 5rem;
    height: 5rem;
  }

  border: 0.15rem solid transparent;
  border-radius: 50%;
  border-top-color: var(--shade-100);
  border-right-color: var(--shade-100);
  border-bottom-color: var(--shade-100);
  width: 4rem;
  height: 4rem;
  animation: circle-loading 1s infinite linear;
}

@keyframes circle-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
