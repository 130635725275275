@import '~darrius/src/styles/mixins/media';

.category-rating {
  :global {
    .stars {
      margin: var(--size-spacing-stack-100) 0;
    }
  }

  &__general,
  &__review {
    border: var(--size-border-width-thin) solid var(--color-neutral-light-400);
    border-radius: var(--size-border-radius-m);
    background-color: var(--color-neutral-light-100);
    padding: var(--size-spacing-inset-stretch-100);
  }

  &__general {
    text-align: center;

    &-rating {
      line-height: var(--font-line-height-compact);
      color: var(--color-neutral-light-600);
      font-size: var(--size-font-m);
      font-weight: var(--font-weight-regular);

      b {
        color: var(--color-neutral-dark-600);
        font-size: var(--size-font-l);
        font-weight: var(--font-weight-semibold);
      }
    }

    :global {
      .stars {
        justify-content: center;
      }
    }

    &-text {
      line-height: var(var(--font-line-height-compact));
      color: var(--color-neutral-dark-400);
      font-size: var(--size-font-base);
      font-weight: var(--font-weight-regular);

      b {
        color: var(--color-neutral-dark-600);
      }
    }
  }

  &__title {
    @include media(normal) {
      margin: var(--size-spacing-inline-900) 0 var(--size-spacing-stack-600) 0;
    }

    margin: var(--size-spacing-inline-500) 0 var(--size-spacing-inline-100) 0;
    line-height: var(--font-line-height-compact);
    color: var(--color-neutral-dark-600);
    font-size: var(--size-font-l);
    font-weight: var(--font-weight-semibold);
  }

  &__review {
    text-align: start;

    &-cli {
      margin-bottom: var(--size-spacing-stack-200);
      line-height: var(--font-line-height-compact);
      color: var(--color-neutral-dark-600);
      font-size: var(--size-font-base);
      font-weight: var(--font-weight-regular);

      b {
        font-weight: var(--font-weight-semibold);
      }
    }

    &-text {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      height: var(--size-spacing-stack-fluid-1200);
      overflow: hidden;
      line-height: var(--font-line-height-normal);
      color: var(--color-neutral-dark-400);
      font-size: var(--size-font-base);
      font-weight: var(--font-weight-regular);
    }

    &-pro {
      line-height: var(--font-line-height-narrow);
      color: var(--color-neutral-dark-200);
      font-size: var(--size-font-s);
      font-weight: var(--font-weight-regular);
    }
  }
}
