.badges {
  margin: var(--size-spacing-stack-500) 0;

  > div {
    display: flex;
    align-items: center;

    img {
      margin-right: var(--size-spacing-inline-100);
    }
  }

  .badges-class {
    margin: var(--size-spacing-stack-100) 0;

    :global {
      .paragraph,
      .stars {
        margin: 0;
      }
    }
  }

  &__youtube {
    :global {
      a.text-link {
        line-height: var(--font-line-height-compact);
        color: var(--shade-300);
        font-size: var(--size-font-base);
        font-weight: var(--font-weight-regular);
      }
    }
  }

  @media (max-width: 768px) {
    margin: var(--size-spacing-stack-300) 0;

    > div {
      margin: var(--size-spacing-stack-100) 0;
    }

    &__youtube {
      :global {
        a.text-link {
          font-size: var(--size-font-m);
        }
      }
    }

    :global {
      .paragraph,
      .stars {
        margin: 0;

        img {
          margin-right: var(--size-spacing-inline-100);
        }
      }

      .stars img {
        margin-top: 0;
        margin-bottom: 0;
        width: 22px;
        height: 22px;
      }
    }
  }
}
