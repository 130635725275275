.chip {
  &__text {
    display: inline-flex;
    align-items: center;
    margin: var(--size-border-width-heavy);
    gap: 1rem;
    border: var(--size-border-width-thin) solid var(--shade-500);
    border-radius: var(--size-border-radius-pill);
    cursor: pointer;
    padding: var(--size-spacing-stack-100) var(--size-spacing-inline-200);
    line-height: var(--font-line-height-compact);
    color: var(--shade-400);
    font-size: var(--size-font-m);

    &:hover {
      border-color: var(--color-action-500);
      background-color: var(--color-action-500);
      color: var(--color-neutral-light-100);
    }
  }
}
