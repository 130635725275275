@import '~darrius/src/styles/mixins/media';

.dialog {
  &__content {
    display: flex;
    position: fixed;
    top: 50%;
    left: 50%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: translate(-50%, -50%);
    z-index: 9999;

    border-radius: var(--size-border-radius-m);
    background: var(--color-neutral-light-100);
    padding: var(--size-spacing-stack-900) var(--size-spacing-inline-1000);

    width: auto;
    min-width: 24rem;

    &_title {
      margin-bottom: var(--size-spacing-stack-300);
      width: 100%;
      line-height: var(--font-line-height-normal);
      color: var(--color-neutral-dark-400);
      font-size: var(--size-font-base);
      font-weight: var(--font-weight-regular);
    }

    &_close {
      all: unset;

      position: absolute;
      top: var(--size-spacing-inline-100);
      right: var(--size-spacing-inline-100);
      z-index: 100;

      cursor: pointer;

      svg {
        color: var(--color-action-500);

        &:hover {
          color: var(--color-action-800);
        }
      }
    }

    @media (max-width: 768px) {
      max-width: 16rem;
    }
  }

  &__overlay {
    position: fixed;

    z-index: 9999;

    background: rgba(0, 0, 0, 0.3);

    width: 100vw;
    height: 100vh;
    inset: 0;
  }
}
