@import '~darrius/src/styles/mixins/media';

.price_banner {
  background-image: var(--linear-gradient-brand-100-500);
}

.budget_btn {
  @include media(normal) {
    position: relative;
    bottom: 30px;
    background-color: transparent;
  }

  position: sticky;
  bottom: 0;
  background-color: var(--shade-900);
  padding: 14px 16px;
}
