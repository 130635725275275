@import '~darrius/src/styles/mixins/media';
@import '~darrius/src/styles/mixins/scale';

.certify {
  @include regular-xs;

  display: flex;
  align-items: center;

  img {
    margin: 2px;
    width: 22px;
    height: 22px;

    &:last-of-type {
      margin-right: var(--size-spacing-inline-100);
    }
  }
}
