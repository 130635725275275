.main_services {
  .button {
    font-size: 16px;
  }

  .main_services__services_title {
    text-align: center;
    color: var(--shade-300);
    font-size: var(--size-font-base);
    font-weight: var(--font-weight-regular);
  }

  a {
    font-size: 16px;
  }
}
