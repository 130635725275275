.countdown__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  background-color: var(--color-brand-100);

  padding: var(--size-spacing-inset-square-300);

  width: 100%;

  span {
    font-size: var(--size-font-base);
  }

  svg {
    margin-right: var(--size-spacing-inline-300);
    color: var(--color-brand-700);
  }
}

.hidden {
  transition:
    visibility 0s 2s,
    opacity 2s linear;

  visibility: hidden;
  opacity: 0;
}
