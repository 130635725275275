@import '~darrius/src/styles/mixins/media';

.similar-request {
  > div:hover,
  > div:active,
  > div:focus {
    outline: var(--size-border-width-heavy) solid var(--color-action-300);
  }

  &__text {
    @include media(normal) {
      -webkit-line-clamp: 5;
      height: var(--size-spacing-stack-fluid-1400);
      line-height: var(--font-line-height-strict);
    }

    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    margin-bottom: var(--size-spacing-stack-fluid-300);
    height: var(--size-spacing-stack-fluid-1400);
    overflow: hidden;
    line-height: var(--font-line-height-normal);
    color: var(--color-neutral-dark-400);
    font-size: var(--size-font-base);
    font-weight: var(--font-weight-regular);
  }

  &__done {
    display: flex;
    align-items: center;
    line-height: var(--font-line-height-compact);
    color: var(--color-positive-600);
    font-size: var(--size-font-base);
    font-weight: var(--font-weight-semibold);

    &-label {
      margin-left: var(--size-spacing-inline-100);
    }
  }
}
