@import '~darrius/src/styles/mixins/media';

.step-by-step-form {
  &__header {
    @include media(normal) {
      margin-bottom: var(--size-spacing-stack-1000);
    }

    display: flex;
    align-items: baseline;
    margin-bottom: var(--size-spacing-stack-500);
  }

  &__back-button {
    all: unset;
    margin-right: var(--size-spacing-inline-300);
    cursor: pointer;

    &:hover,
    &:active,
    &:focus {
      outline: var(--size-border-width-heavy) solid var(--color-action-300);
    }
  }

  &__title {
    flex: 1 1 0%;
    text-align: center;
    line-height: var(--font-line-height-compact);
    color: var(--color-neutral-dark-600);
    font-size: var(--size-font-xl);
    font-weight: var(--font-weight-semibold);

    &--big {
      @include media(normal) {
        font-size: var(--size-font-3xl);
      }
      margin-bottom: var(--size-spacing-stack-200);
    }
  }

  &__item {
    @include media(normal) {
      img {
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    margin-bottom: var(--size-spacing-stack-300);
    border: var(--size-border-width-thin) solid var(--color-neutral-light-300);
    border-radius: var(--size-border-radius-m);
    background-color: var(--color-neutral-light-100);
    list-style: none;
    line-height: var(--font-line-height-normal);
    color: var(--color-neutral-dark-400);
    font-size: var(--size-font-m);
    font-weight: var(--font-weight-regular);

    &:hover {
      background-color: var(--color-neutral-light-200);
    }

    &:active {
      outline: var(--size-border-width-heavy) solid var(--color-action-300);
    }

    label {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      padding: var(--size-spacing-stack-400) var(--size-spacing-inline-300);
    }

    input {
      display: none;
    }
  }

  .text-left {
    @include media(normal) {
      text-align: left;
    }
  }
}

.search {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--size-spacing-stack-300);
  border: var(--size-border-width-thick) solid var(--color-neutral-light-500);
  border-radius: var(--size-border-radius-m);
  padding: var(--size-spacing-inline-100) var(--size-spacing-stack-100)
    var(--size-spacing-inline-100) var(--size-spacing-inline-300);

  &:hover {
    border: var(--size-border-width-thick) solid var(--action-500);
  }

  &__clean {
    border: none;
    background-color: var(--color-neutral-light-100);
    cursor: pointer;

    &_isActived {
      svg {
        fill: var(--color-action-500);
      }
    }

    svg {
      fill: var(--color-action-300);
      width: 24px;
      height: 24px;
    }
  }

  label {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__label {
    svg {
      fill: var(--color-action-500);
      width: 35px;
      height: 35px;
    }
  }

  input {
    margin-right: var(--size-spacing-inline-400);
    margin-left: var(--size-spacing-inline-300);
    border: initial;
    background-color: var(--shade-900);
    padding: 0;
    width: 75%;

    &::placeholder {
      color: var(--shade-500);
      font-size: var(--size-font-base);
    }
  }
}

.search__clean_isActived {
  svg {
    fill: var(--color-action-500);
  }
}
