@import '~darrius/src/styles/mixins/media';

.container {
  @include media(normal) {
    display: none;
  }
}

.card {
  border-radius: var(--size-border-radius-m);
  background-color: var(--color-action-500);
  padding: var(--size-spacing-stack-500) var(--size-spacing-inline-300);
  text-align: center;
  color: var(--color-neutral-light-100);
}

.image {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: var(--size-spacing-stack-500);
}

.title {
  margin-bottom: var™(--size-spacing-stack-200);
  line-height: var(--font-line-height-compact);
  font-size: var(--size-font-xl);
  font-weight: var(--font-weight-semibold);
}

.text {
  margin-bottom: var(--size-spacing-stack-500);
  line-height: var(--font-line-height-normal);
  font-size: var(--size-font-l);

  strong {
    font-weight: var(--font-weight-semibold);
  }
}

.main-button {
  margin-bottom: var(--size-spacing-stack-100);
}
