@import '~darrius/src/styles/mixins/media';

.category-form {
  @include media(medium) {
    padding: var(--size-spacing-inset-square-1000);
  }

  position: relative;
  border: var(--size-border-width-thin) solid var(--color-neutral-light-400);
  border-radius: var(--size-border-radius-m);
  background-color: var(--color-neutral-light-100);
  padding: var(--size-spacing-inset-square-300);
  min-height: 400px;
  font-family: var(--font-family-normal);

  &__content {
    min-height: 370px; // Form min-height - form cta height
  }

  // Mensagem de "Orçamentos em até 60 minutos" na parte de baixo do formulário
  &__deadline-info {
    @include media(normal) {
      padding: var(--size-spacing-stack-100) var(--size-spacing-inline-400);
    }

    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: var(--size-spacing-stack-700);
    border-radius: var(--size-border-radius-s);
    background-color: var(--color-positive-100);
    padding: var(--size-spacing-inset-square-100);
    text-align: center;
    line-height: var(--font-line-height-narrow);
    color: var(--color-positive-700);
    font-size: var(--size-font-base);
    font-weight: var(--font-weight-semibold);

    img {
      margin-right: var(--size-spacing-inline-100);
    }
  }

  :global {
    .form > .form__container:nth-last-child(5) .form__actions {
      margin-top: 155px !important;
    }
  }

  // Barra de progresso no topo do step json-form
  :global {
    .progressbar {
      margin-bottom: var(--size-spacing-stack-500);
      height: var(--size-spacing-stack-100);

      &[value],
      &::-webkit-progress-value,
      &::-webkit-progress-bar,
      &.progressbar--completed {
        border-radius: var(--size-border-radius-pill);
        background-color: var(--color-neutral-light-400);
      }

      &::-webkit-progress-value,
      &.progressbar--completed::-webkit-progress-value {
        border-radius: var(--size-border-radius-pill);
        background-color: var(--color-action-500);
      }
    }

    // Título no topo do formulário no step do json-form
    .__headerMarkup__ {
      @include media(normal) {
        margin-bottom: var(--size-spacing-stack-1000);
      }

      margin-bottom: var(--size-spacing-stack-500);
      text-align: center;

      h2 {
        line-height: var(--font-line-height-compact);
        color: var(--color-neutral-dark-600);
        font-size: var(--size-font-xl);
        font-weight: var(--font-weight-semibold);
      }

      small {
        display: block;
        margin-top: var(--size-spacing-stack-200);
        line-height: var(--font-line-height-normal);
        color: var(--color-neutral-dark-400);
        font-size: var(--size-font-base);
        font-weight: var(--font-weight-regular);
      }

      strong {
        font-weight: var(--font-weight-regular);
      }

      .form__info {
        text-align: center;
        line-height: var(--font-line-height-compact);
        color: var(--color-neutral-dark-600);
        font-size: var(--size-font-xl);
        font-weight: var(--font-weight-semibold);
      }

      .deadline-info {
        display: none;
      }
    }

    .form {
      select {
        // Necessário para estilização do select no Safari
        -webkit-appearance: none;
      }

      textarea {
        min-height: 10rem;
      }

      &__container {
        display: flex;
        border: 0;
      }

      // Não pode ser &__field devido a problema de especificidade, fazendo ter um hover indesejado
      .form__field {
        display: block;
        border: 0;
        background-color: transparent;
        padding: 0;
      }

      &__input {
        margin: 0;
        border: var(--size-border-width-thin) solid
          var(--color-neutral-light-500);
        border-radius: var(--size-border-radius-s);
        background-color: var(--color-neutral-light-100);
        cursor: pointer;
        padding: var(--size-spacing-inset-square-300);
        width: 100%;
        line-height: var(--font-line-height-compact);
        color: var(--color-neutral-dark-400);
        font-family: var(--font-family-normal);
        font-size: var(--size-font-m);
        font-weight: var(--font-weight-regular);

        &::placeholder {
          color: var(--color-neutral-dark-100);
        }

        &:not(:disabled) {
          &:hover {
            border-color: var(--color-neutral-light-700);
            background-color: var(--color-neutral-light-200);
          }

          &:active,
          &:focus {
            outline: var(--size-border-width-heavy) solid
              var(--color-action-300);
          }
        }

        &:disabled {
          background-color: var(--color-neutral-light-300);
          cursor: not-allowed;
          color: var(--color-neutral-dark-100);
        }

        &--invalid {
          border-color: var(--color-negative-600);

          &:focus-within {
            outline: var(--size-border-width-thick) solid
              var(--color-negative-600);
          }
        }
      }

      &__label {
        display: block;
        margin-bottom: var(--size-spacing-stack-fluid-500);
        line-height: var(--font-line-height-compact);
        color: var(--color-neutral-dark-600);
        font-size: var(--size-font-m);
        font-weight: var(--font-weight-semibold);
      }

      &__check {
        margin-bottom: var(--size-spacing-stack-fluid-500);
        list-style: none;

        .form__input {
          padding: var(--size-spacing-stack-100) var(--size-spacing-stack-300);
        }

        input[type='checkbox'] {
          position: absolute;
          opacity: 0;
          z-index: 1;
          margin: 0;
          width: 2rem;
          height: 2rem;
        }

        input:hover ~ label::before {
          border-color: var(--color-neutral-light-700);
          background-color: var(--color-neutral-light-200);
        }

        input:active,
        input:focus {
          ~ label::before {
            outline: var(--size-border-width-thick) solid
              var(--color-action-300);
          }
        }

        input:checked ~ label::before {
          background-color: var(--color-action-500);
          content: url('data:image/svg+xml;charset=utf-8,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2718%27 height=%2718%27%3E%3Cg fill=%27none%27 fill-rule=%27evenodd%27%3E%3Crect width=%2718%27 height=%2718%27 rx=%274%27/%3E%3Cpath fill=%27%23FFF%27 d=%27M4 10.802l2.002-2.039 2.179 2.217L14.056 5 16 6.979 8.123 15z%27/%3E%3C/g%3E%3C/svg%3E');
        }

        input:not(:disabled) {
          cursor: pointer;
        }

        label#checkboxLink {
          flex-wrap: wrap;
          gap: 0.7rem;
        }

        label {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 1rem;

          cursor: pointer;
          line-height: var(--font-line-height-compact);
          color: var(--color-neutral-dark-500);
          font-size: var(--size-font-m);
          font-weight: var(--font-weight-regular);
        }

        label#checkboxLink::before {
          margin-right: 4px;
        }

        label::before {
          @include media(normal) {
            width: 1.25rem;
            min-width: 1.25rem;
            height: 1.25rem;
          }

          border: var(--size-border-width-thick) solid
            var(--color-neutral-light-500);
          border-radius: var(--size-border-radius-s);
          background-color: var(--color-neutral-light-100);
          width: 1.4rem;
          min-width: 1.4rem;
          height: 1.4rem;
          content: '';
        }

        .form__input {
          margin-bottom: 0;
        }
      }

      &__radio {
        display: flex;
        align-items: center;
        margin-bottom: var(--size-spacing-stack-fluid-500);
        list-style: none;
        gap: 1rem;

        input {
          cursor: pointer;
          width: 1.4rem;
          height: 1.4rem;
          accent-color: var(--color-action-500);
        }

        label {
          cursor: pointer;
          line-height: var(--font-line-height-compact);
          color: var(--color-neutral-dark-500);
          font-size: var(--size-font-m);
          font-weight: var(--font-weight-regular);
        }
      }

      &__message {
        display: block;
        margin-top: var(--size-spacing-stack-100);
        line-height: var(--font-line-height-compact);
        color: var(--color-neutral-dark-400);
        font-family: var(--font-family-normal);
        font-size: var(--size-font-base);
        font-weight: var(--font-weight-regular);

        &--invalid {
          color: var(--color-negative-600);
        }
      }

      &__actions {
        display: flex;
        justify-content: space-between;
        margin-top: var(--size-spacing-stack-fluid-900);
        gap: 1rem;
      }

      &__steps {
        list-style: none;
      }

      &__terms-link,
      &__label-link {
        float: right;
        cursor: pointer;
        color: var(--color-action-500);
        font-size: var(--size-font-base);

        &:hover {
          text-decoration: underline;
          color: var(--color-action-700);
        }

        &:active,
        &:focus {
          outline: var(--size-border-width-heavy) solid var(--color-action-300);
        }
      }

      label[for='zip_code'] {
        display: inline-block;
        margin-bottom: var(--size-spacing-stack-100);
        line-height: var(--font-line-height-compact);
        color: var(--color-neutral-dark-300);
        font-family: var(--font-family-normal);
        font-size: var(--size-font-m);
        font-weight: var(--font-weight-regular);
      }

      div:nth-child(2) > ul > li {
        display: flex;
        align-items: center;
        margin: 0;

        /* stylelint-disable selector-max-compound-selectors */
        label {
          cursor: pointer;
          font-size: var(--size-font-base);
        }

        a.form__terms-link {
          line-height: 0;
        }

        a {
          line-height: var(--font-line-height-normal);
        }
      }

      // Último passo do formulário
      &__container:last-child {
        // Mensagem após input de DDD + Celular
        .form__field:nth-last-of-type(3)::after {
          display: block;
          margin-top: var(--size-spacing-stack-100);
          line-height: var(--font-line-height-normal);
          color: var(--color-neutral-dark-300);
          font-family: var(--font-family-normal);
          font-size: var(--size-font-base);
          font-weight: var(--font-weight-regular);
          content: 'Vamos confirmar seu celular através de uma mensagem de texto!';
        }

        // Checkbox de política de privacidade no final do formulário
        .form__field:nth-child(5) {
          margin: var(--size-spacing-stack-fluid-500) 0
            var(--size-spacing-stack-fluid-900) 0;

          .form__check {
            display: flex;
            align-items: center;
            margin: 0;

            label {
              cursor: pointer;
              gap: 0.5rem;
              font-size: var(--size-font-base);
            }

            a {
              line-height: var(--font-line-height-normal);
            }
          }
        }

        .form__field {
          margin-bottom: var(--size-spacing-stack-fluid-500);
        }

        .form__label {
          display: inline-block;
          margin-bottom: var(--size-spacing-stack-100);
          line-height: var(--font-line-height-compact);
          color: var(--color-neutral-dark-300);
          font-family: var(--font-family-normal);
          font-size: var(--size-font-m);
          font-weight: var(--font-weight-regular);
        }
      }

      // Termometro abaixo do campo de Informações Adicionais
      &__thermometer {
        --triangle-size: 14px;
        position: relative;
        margin-top: calc(var(--size-spacing-stack-300) + var(--triangle-size));
        border-radius: var(--size-border-radius-s);
        background-color: var(--color-brand-200);
        padding: var(--size-spacing-stack-300) var(--size-spacing-inline-200);
        text-align: center;
        line-height: var(--font-line-height-normal);
        font-size: var(--size-font-base);
        font-weight: var(--font-weight-semibold);

        &::before {
          position: absolute;
          top: calc(var(--triangle-size) * -1);
          left: calc(50% - var(--triangle-size));
          border-right: var(--triangle-size) solid transparent;
          border-bottom: var(--triangle-size) solid var(--color-brand-200);
          border-left: var(--triangle-size) solid transparent;
          width: 0;
          height: 0;
          content: '';
        }

        &--poor {
          color: var(--color-neutral-dark-400);
        }

        &--regular {
          color: var(--color-neutral-dark-400);
        }

        &--good {
          color: var(--color-positive-700);
        }
      }

      &__social-trigger {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: var(--size-spacing-stack-500);
        text-align: center;
      }

      &__social-trigger img {
        margin-right: var(--size-spacing-inline-100);
        width: var(--size-spacing-inline-500);
        height: var(--size-spacing-stack-500);
      }

      &__social-trigger span {
        line-height: var(--font-line-height-normal);
        color: var(--color-neutral-dark-400);
        font-size: var(--size-font-base);
        font-weight: var(--font-weight-regular);
      }
    }
  }
}
