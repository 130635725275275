.confirmation-badge {
  display: flex;
  align-items: center;
  margin-bottom: var(--size-spacing-inline-100);
  line-height: var(--font-line-height-compact);
  color: var(--color-positive-600);
  font-size: var(--size-font-base);
  font-weight: var(--font-weight-semibold);

  &__label {
    margin-left: var(--size-spacing-inline-100);
  }
}
