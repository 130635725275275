@import '~darrius/src/styles/mixins/media';

.form__loading,
.form__error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 30rem;
  text-align: center;

  // Faz esconder a mensagem de "Orçamentos em até 60 minutos" na parte de baixo do formulário quando esses elementos estiverem visíveis
  + .category-form__deadline-info {
    display: none;
  }

  :global {
    .paragraph {
      margin-top: var(--size-spacing-stack-800);

      h4 {
        font-size: var(--size-font-xl);
      }

      p {
        font-size: var(--size-font-m);
      }
    }
  }
}

.form__text {
  margin-top: var(--size-spacing-stack-800);
}

.form__error {
  text-align: center;
  line-height: var(--font-line-height-compact);
  color: var(--color-negative-600);
  font-size: var(--size-font-xl);
  font-weight: var(--font-weight-semibold);
}

.show-upload {
  display: block;
}

.hide-upload {
  display: none;
}

