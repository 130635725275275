@import '~darrius/src/styles/mixins/media';

.pro-card {
  display: block;
  border: var(--size-border-width-thin) solid var(--color-neutral-light-400);
  border-radius: var(--size-border-radius-m);
  background-color: var(--color-neutral-light-100);
  padding: var(--size-spacing-inset-stretch-100);

  &:hover,
  &:active,
  &:focus {
    outline: var(--size-border-width-heavy) solid var(--color-action-300);
  }

  :global {
    .stars {
      margin: 0.25rem 0;
    }
  }

  &__bio {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: var(--size-spacing-stack-500);

    &-info {
      flex: 1 1 0%;
      margin-left: var(--size-spacing-inline-500);
    }
  }

  &__name {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    width: 100%;
    overflow: hidden;
    line-height: var(--font-line-height-normal);
    color: var(--color-neutral-dark-600);
    font-size: var(--size-font-m);
    font-weight: var(--font-weight-semibold);
  }

  &__recommendation-count {
    line-height: var(--font-line-height-normal);
    color: var(--color-neutral-dark-200);
    font-size: var(--size-font-base);
    font-weight: var(--font-weight-regular);
  }

  &__about {
    &-title {
      margin-bottom: var(--size-spacing-stack-200);
      line-height: var(--font-line-height-compact);
      color: var(--color-neutral-dark-600);
      font-size: var(--size-font-base);
      font-weight: var(--font-weight-semibold);
    }

    &-text {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      width: 100%;
      height: var(--size-spacing-stack-fluid-1200);
      overflow: hidden;
      line-height: var(--font-line-height-normal);
      color: var(--color-neutral-dark-400);
      font-size: var(--size-font-base);
      font-weight: var(--font-weight-regular);
    }
  }

  &__badges {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: var(--size-spacing-stack-500) 0;
  }

  &__badge {
    display: flex;
    flex-direction: row;
    justify-self: center;

    &-text {
      margin: 0 0 var(--size-spacing-stack-300) var(--size-spacing-inline-100);
      line-height: var(--font-line-height-normal);
      color: var(--color-neutral-dark-200);
      font-size: var(--size-font-s);
      font-weight: var(--font-weight-semibold);
    }
  }

  &__recommendation {
    border-radius: var(--size-border-radius-m);
    background-color: var(--color-neutral-light-200);
    padding: var(--size-spacing-inset-square-500);

    &-header {
      line-height: var(--font-line-height-normal);
      color: var(--color-neutral-dark-600);
      font-size: var(--size-font-base);
      font-weight: var(--font-weight-semibold);
    }

    &-text {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      margin-top: var(--size-spacing-stack-200);
      width: 100%;
      height: var(--size-spacing-stack-fluid-1200);
      overflow: hidden;
      line-height: var(--font-line-height-normal);
      color: var(--color-neutral-dark-400);
      font-size: var(--size-font-base);
      font-weight: var(--font-weight-regular);
      font-style: var(--font-style-quote);
    }
  }

  &__author {
    margin-top: var(--size-spacing-stack-200);

    &-text {
      margin-top: var(--size-spacing-stack-200);
      line-height: var(--font-line-height-strict);
      color: var(--color-neutral-dark-200);
      font-size: var(--size-font-s);
      font-weight: var(--font-weight-semibold);
    }
  }
}
