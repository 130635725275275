.upload-image {
  position: absolute;
  bottom: 23%;
  left: 0;
  padding: 0 var(--size-spacing-inline-300);
  width: 100%;

  form {
    text-align: center;
  }

  .warn_message {
    margin-top: var(--size-spacing-stack-100);
    margin-bottom: 0;
    text-align: center;
    line-height: var(--font-line-height-compact);
    color: var(--color-neutral-dark-600);
    font-size: var(--size-font-sm);
  }

  .warn_error_section {
    margin-top: var(--size-spacing-stack-100);

    .warn_message {
      margin-left: calc(var(--size-spacing-inline-100) / 2);
      color: var(--color-negative-500);
    }

    svg {
      fill: var(--color-negative-500);
      width: var(--size-spacing-inline-400);
      height: var(--size-spacing-stack-400);
    }
  }

  svg {
    width: var(--size-spacing-inline-500);
    height: var(--size-spacing-stack-500);
    vertical-align: top;
  }

  input[type='file'] {
    display: none;
  }

  .custom-upload-button {
    display: block;
    justify-content: center;
    transition: 0.2s;
    border: solid var(--size-border-width-thick) var(--color-action-500);
    border-radius: var(--size-border-radius-m);
    background-color: transparent;
    cursor: pointer;
    padding: var(--size-spacing-inset-squish-200);
    width: 100%;
    color: var(--color-action-500);
    gap: var(--size-spacing-inline-100);
    font-family: var(--font-family-normal);
    font-size: var(--size-font-l);
    font-weight: var(--font-weight-semibold);
  }

  .custom-upload-button:hover {
    border-color: transparent;
  }

  @media (min-width: 768px) {
    padding: 0 var(--size-spacing-inline-1000);
  }
}

.image-preview {
  display: inline-block;
  position: relative;

  img {
    border-radius: var(--size-border-radius-m) var(--size-border-radius-m) 0 0;
    max-width: 100%;
    max-height: calc(var(--size-spacing-stack-1100) * 1.6);
  }
}

.remove-image-button {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: var(--size-spacing-stack-100);
  margin-right: var(--size-spacing-inline-100);
  border: none;
  border-radius: 50%;
  background-color: var(--color-negative-500);
  cursor: pointer;
  width: var(--size-spacing-inline-500);
  height: var(--size-spacing-stack-500);
  text-align: center;
  color: var(--color-neutral-light-100);

  svg {
    width: var(--size-spacing-inline-300);
    height: var(--size-spacing-stack-300);
  }
}
