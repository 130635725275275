.price_range__title_small {
  font-size: 100%;
  font-weight: var(--font-weight-regular);
}

.price_range__title_big p {
  margin-bottom: var(--size-spacing-stack-300);
  line-height: var(--font-line-height-strict);
  font-size: var(--size-font-6xl);
}

.price_range__title_medium p {
  margin-bottom: var(--size-spacing-stack-200);
  line-height: var(--font-line-height-compact);
  font-size: var(--size-font-xl);
}

.price_range__title_big p,
.price_range__title_medium p {
  color: var(--shade-100);
  font-weight: var(--font-weight-semibold);
}
