@import '~darrius/src/styles/mixins/media';

.category-card {
  :global {
    .col-fill {
      background-color: var(--brand-800);
    }

    .content {
      @include media(small, normal) {
        .paragraph {
          margin: 36px 0;
        }
      }

      @include media(large) {
        .paragraph {
          margin: 72px 0;
        }
      }

      @include media(normal) {
        padding: 10px 100px;
      }

      padding: 20px;
    }

    .image {
      width: 48px;
    }
  }
}
