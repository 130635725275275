.status__root {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  gap: var(--size-spacing-stack-100);
}

@keyframes circle-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.status__title {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;

  gap: var(--size-spacing-stack-100);
  line-height: var(--font-line-height-normal);
  font-size: var(--size-font-m);
  font-weight: var(--font-weight-regular);

  img {
    margin-top: calc(var(--size-spacing-stack-100) / 2);
  }

  &--finalized {
    color: var(--color-positive-600);
  }

  &--blocked {
    color: var(--color-negative-600);
  }

  &--finalized_zero_lead,
  &--notification {
    color: var(--color-warning-500);
  }

  &--searching {
    color: var(--color-highlight-500);

    img {
      animation: circle-loading 1s linear infinite;
      line-height: 0;
    }
  }

  &--waiting {
    color: var(--color-highlight-500);
  }
}

.status__description {
  line-height: var(--font-line-height-normal);
  color: var(--color-neutral-dark-200);
  font-size: var(--size-font-s);
  font-weight: var(--font-weight-regular);

  strong {
    line-height: var(--size-font-m);
    color: var(--color-neutral-dark-500);
    font-weight: var(--font-weight-semibold);
  }
}
