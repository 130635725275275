.flat-card {
  display: block;
  border: var(--size-border-width-thin) solid var(--color-neutral-light-400);
  border-radius: var(--size-border-radius-m);
  background-color: var(--color-neutral-light-100);

  &.selectable {
    &:hover,
    &:active,
    &:focus {
      outline: var(--size-border-width-heavy) solid var(--color-action-300);
    }
  }
}
