@import '~darrius/src/styles/mixins/scale';
@import '~darrius/src/styles/mixins/media';
@import '~darrius/src/styles/mixins/display';

.services-categories {
  @include media(normal) {
    display: flex;
    align-items: center;
    margin-top: 0 !important;
    margin-bottom: var(--size-spacing-stack-200) !important;
    padding: 0 !important;
    padding-right: 16px;

    &:not(&--fixed) {
      border-top: var(--size-border-width-thin) solid
        var(--color-neutral-light-400);
      border-bottom: var(--size-border-width-thin) solid
        var(--color-neutral-light-400);
      border-radius: 0;
      box-shadow: var(--none, none);
      padding-bottom: 0;

      .categories {
        position: relative;
      }

      .category:hover,
      .category:active,
      .category:focus,
      .category:focus-within {
        ~ .subcategories {
          display: initial;
        }
      }

      .subcategories {
        position: absolute;

        &:hover,
        &:active,
        &:focus,
        &:focus-within {
          display: initial;
        }
      }
    }

    &--fixed {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1001;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      padding-bottom: 0;
      width: 100%;
      height: auto;

      .card-header {
        display: var(--none, none);
      }

      .category:hover,
      .category:active,
      .category:focus,
      .category:focus-within {
        ~ .subcategories {
          display: initial;
        }
      }

      .subcategories:hover,
      .subcategories:active,
      .subcategories:focus,
      .subcategories:focus-within {
        display: initial;
      }
    }

    .service-link {
      height: 50px;
    }
  }

  margin-top: 0;
  margin-bottom: 32px;
  padding-bottom: 16px;
  overflow: visible;

  /* stylelint-disable selector-max-compound-selectors */
  .show-more:checked {
    ~ .button .less {
      display: initial;
    }

    ~ .button .more {
      display: var(--none, none);
    }

    ~ .categories .hide-sm {
      display: initial;
    }
  }

  .title {
    @include regular-xs-strong;

    @include media(normal) {
      @include bold-s;
    }
  }

  .categories {
    @include media(normal) {
      padding: 0 30px;
    }

    z-index: 1001;
    padding: 0 15px;
  }

  .category {
    @include media(normal) {
      padding: 0 16px;
      width: auto;
    }

    z-index: 1001;
    border-radius: 16px;
    width: 80px;
    height: 100px;
    text-decoration: 0;
    color: var(--shade-100);

    &:hover,
    &:focus,
    &:active {
      background-color: var(--brand-900);
    }

    &__text {
      @include regular-xxs;
    }

    .image.square {
      width: 48px;
    }
  }

  .subcategories {
    @include media(normal) {
      top: 7rem;
    }

    @include media(large) {
      top: 7.25rem;
    }

    position: fixed;
    top: 7.25rem;
    left: 0;
    z-index: 1000;
    border-top: 0.1rem solid var(--shade-600);
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.12);
    background-color: var(--shade-900);
    padding: 1rem 3rem;
    width: 100%;

    .list {
      margin-top: 0;
    }

    ul {
      padding: 0;
    }

    li {
      overflow: hidden;
    }
  }

  .twocolumns {
    @include media(normal) {
      columns: 2;
    }

    columns: 1;
  }
}
