@import '~darrius/src/styles/mixins/media';

.related-category {
  font-weight: var(--font-weight-semibold);
  font-style: italic;
}

.step-by-step-form__item {
  @include media(normal) {
    img {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  margin-bottom: var(--size-spacing-stack-300);
  border: var(--size-border-width-thin) solid var(--color-neutral-light-300);
  border-radius: var(--size-border-radius-m);
  background-color: var(--color-neutral-light-100);
  list-style: none;
  line-height: var(--font-line-height-normal);
  color: var(--color-neutral-dark-400);
  font-size: var(--size-font-m);
  font-weight: var(--font-weight-regular);

  &:hover {
    background-color: var(--color-neutral-light-200);
  }

  &:active {
    outline: var(--size-border-width-heavy) solid var(--color-action-300);
  }

  label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: var(--size-spacing-stack-400) var(--size-spacing-inline-300);
  }

  input {
    display: none;
  }
}
