@import '~darrius/src/styles/mixins/media';

.toast {
  @include media(medium) {
    min-width: 480px;
  }

  :global {
    .Toastify__toast {
      padding: var(--size-spacing-stack-fluid-200);
    }

    .Toastify__toast-body {
      display: flex;
      align-items: center;
      gap: 1.5rem;
      padding: 0;
      font-size: var(--size-font-l);
    }
  }

  button {
    all: unset;
    display: flex;
    align-items: center;
    font-weight: 600;

    &:hover {
      filter: brightness(0.8);
    }

    &:focus {
      outline: var(--size-border-width-heavy) solid var(--color-action-300);
    }
  }
}
